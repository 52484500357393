(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:MultiWalletCardInvoiceCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('MultiWalletCardInvoiceCtrl', MultiWalletCardInvoiceCtrl);

  function MultiWalletCardInvoiceCtrl(company,
    user,
    _,
    $state,
    FormControl,
    Auth,
    $location,
    $q,
    isAuthenticated,
    LoggedUser,
    OrderService,
    $translate,
    ItemList,
    cardTypeId,
    DataReconciliation,
    workingDays,
    moment) {
    var vm = this
      , formControl = new FormControl()
      , userToAuthenticate = {}
      , deferredFormControl
      , multiWalletItems = 'multiWalletItems'
      , itemList = new ItemList();

    vm.prevState = isAuthenticated ? 'typeSelector' : 'contract';
    vm.company = company;
    vm.user = user;
    vm.invoiceForm = {};
    vm.newCardOrderType = '1';
    vm.cardTypeId = cardTypeId;
    vm.workingDays = workingDays;

    vm.topUpDate = vm.workingDays[0].date;

    vm.items = itemList.getItemListStorage(multiWalletItems);
    itemList.setItems(vm.items);

    vm.getSize = function getSize() {
      return itemList.getSize();
    };

    vm.hasCompanyDeliveryAddress = function hasCompanyDeliveryAddress() {
      return vm.company.deliveryAddress.zipCode && vm.company.deliveryAddress.city && vm.company.deliveryAddress.address;
    };

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      deferredFormControl = $q.defer();

      OrderService.setInProgress();

      formControl
        .process(deferredFormControl.promise);

      processOrder();
    };

    function saveDataReconciliations() {
      var params = {
        userId: user.id,
        companyId: company.id
      };

      _.forEach(vm.items, function (item) {
        item.topUpDate = moment(vm.topUpDate).format();
      });

      return formControl.process(DataReconciliation.createDataReconciliations(params, vm.items).$promise)
        .then(OrderService.clearInProgress)
        .then(OrderService.clearStorageItems);
    }

    function processOrder() {
      if (isAuthenticated) {
        processAuthenticatedOrder();
      } else {
        processAnonymousOrder();
      }
    }

    function processAuthenticatedOrder() {
      var params = {
        userId: user.id,
        companyId: company.id
      };

      _.forEach(vm.items, function (item) {
        item.topUpDate = moment(vm.topUpDate).format();
      });

      formControl.process(DataReconciliation.createDataReconciliations(params, vm.items).$promise)
        .then(function () {
          setSucccessStateDatas();
          $state.go('success', { type: $translate.instant('order.type.' + vm.newCardOrderType + '.url') });
        })
        .then(OrderService.clearInProgress)
        .then(OrderService.clearStorageItems);
    }

    function processAnonymousOrder() {
      userToAuthenticate = {
        identifier: user.email,
        password: user.password
      };

      user
        .$save()
        .then(silentLogin)
        .then(saveCompany)
        .then(setCompany)
        .then(saveDataReconciliations)
        .then(silentlogout)
        .then(normalLogin)
        .then(restoreCompanySession)
        .then(setSucccessStateDatas)
        .catch(deferredFormControl.reject);
    }

    function silentLogin() {
      return Auth.silentLogin(userToAuthenticate);
    }

    function saveCompany() {
      var UserCompany = user.getCompany();

      company.userId = user.id;
      company = _.defaultsDeep(new UserCompany(), company);
      return company.$save();
    }

    function setCompany() {
      return LoggedUser.setCompany(company.id);
    }

    function silentlogout() {
      return Auth.silentlogout();
    }

    function normalLogin() {
      var orderTypeName = $translate.instant('order.type.' + vm.newCardOrderType + '.url');

      deferredFormControl.resolve();
      $location.setNextUrl('order-with-contract/' + orderTypeName + '/success');
      return Auth.login(userToAuthenticate);
    }

    function setSucccessStateDatas() {
      OrderService.setSuccessData({
        isAuthenticated: isAuthenticated,
        orderType: vm.newCardOrderType
      });
    }

    function restoreCompanySession() {
      var UserCompany = user.getCompany()
        , userActiveCompany = LoggedUser.getCompany();

      UserCompany.get({ companyId: userActiveCompany.id });
    }
  }
}());
